<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

/**
 * Advanced-table component
 */

export default {
  page: {
    title: "Order History",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, OrderReciept },
  data() {
    return {
      tableData: [],

      title: "Order History",
      items: [
        {
          text: "View",
        },
        {
          text: "Order History",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderData: [],
      orderDetails: [],
      invoice: [],
      branches: [],
      branchID: "",
      restBranchID: this.$storageData.profile.restBranchID,
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",

      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatus: "",
      orderStatusOptions: [
        { statusID: "2", statusName: "Placed" },
        { statusID: "3", statusName: "Recieved" },
        { statusID: "4", statusName: "Confirmed" },
        { statusID: "5", statusName: "Preparing" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" },
      ],

      fields: [
        // {
        //     key: "Action",
        //     sortable: false,
        // },
        {
          key: "orderID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "userName",
          lable: "Customer Name",
          sortable: true,
        },
        {
          key: "restBranchName",
          sortable: true,
        },
        {
          key: "orderStatus",
          sortable: true,
        },
        {
          key: "totalSum",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "placedOn",
          sortable: true,
        },
        {
          key: "deliveryDate",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    if (this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11) {
      this.getRestaurantCountry();
    } else {
      this.readOrdersData();
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "OrderHistory",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OrderHistory.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    readOrdersData() {
      this.axios
        .post(this.$loggedRole+"/viewAllOrderHistory", {
          restBranchID:
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? this.restBranchID
              : this.$storageData.profile.restBranchID,
          dateFilter: this.daterange,
          orderStatus: this.orderStatus != "" ? this.orderStatus.statusID : "",
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
        });
    },
    dateFilter() {
      // alert(this.daterange);
      //this.readOrdersData();
    },
    // applyFilter(){
    //     this.readOrdersData();
    // },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then((result) => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          this.invoice = result.data.invoice;
          //  console.log(result.data.data.orderedItemArr);
          this.orderedItemArr = result.data.data.orderedItemArr;
          // this.orderItemAddonArr = result.data.orderItemAddonArr;
        });
    },
    getRestaurantBrandsByCompanyID() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: this.$storageData.profile.companyID,
        })
        .then((response) => {
          this.brandArr = response.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID:
            this.$storageData.login_type == 1
              ? this.restaurantID.restID
              : this.$storageData.profile.restID,
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 11) {
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },

    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          this.readOrdersData();
        });
    },
    onchangeBranch() {
      this.restBranchID = this.branchID.restBranchID;
      this.readOrdersData();
    },
  },
  middleware: "authentication",
};
</script>





<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="card" style="margin-top: -30px">
        <div class="card-body">
          <div class="inner mb-2 row">
            <div
              class="col-md-2"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :options="countryArr"
                track-by="country"
                label="country"
                @input="getRestaurantCities()"
                :show-labels="false"
              ></multiselect>
            </div>
            <!-- Cities List-->
            <div
              class="col-md-2"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select City </label>
              <multiselect
                v-model="city"
                :options="cityArr"
                track-by="city"
                label="city"
                @input="readRestaurantBranchData()"
                :show-labels="false"
              ></multiselect>
            </div>
            <!--END Cities List-->

            <div class="col-md-3 mb-3" v-if="this.$storageData.profile.empTypeID == 11">
              <label>Select Restaurant Brand</label>
              <multiselect
                v-model="brandData"
                :options="brandArr"
                track-by="restID"
                label="name"
                @input="readRestaurantBranchData()"
                :show-labels="false"
              ></multiselect>
            </div>

            <div
              class="col-md-3"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select Restaurant Branch </label>
              <multiselect
                v-model="branchID"
                :options="branches"
                :show-labels="false"
                track-by="restBranchID"
                label="branchName"
                @input="onchangeBranch()"
              ></multiselect>
            </div>
            <div class="form-group mb-3 col-md-3">
              <label>Date Range</label>
              <br />
              <date-picker
                v-model="daterange"
                range
                append-to-body
                lang="en"
                confirm
                value-type="format"
                @change="onchangeBranch()"
              ></date-picker>
            </div>
            <div class="col-md-3">
              <label>Order Status</label>
              <multiselect
                v-model="orderStatus"
                :options="orderStatusOptions"
                @input="onchangeBranch()"
                track-by="statusID"
                label="statusName"
              ></multiselect>
            </div>
            <!-- <div class="col-md-3">
                  <button type="button" class="btn btn-themeOrange w-md waves-effect waves-light" @click="applyFilter();" style="margin-top: 27px;">Apply filter</button>
                </div> -->
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->

                <template v-slot:cell(orderID)="data">
                  <span
                    style="cursor: pointer; color: rgb(243, 118, 106);"
                    @click="viewOrderDetails(data.item.id)"
                    >{{ data.item.orderID }}</span
                  >
                </template>
                <template v-slot:cell(totalSum)="data">
                  <span>₹ {{ data.item.totalSum }}</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderedItemArr="this.orderedItemArr"
      :orderStatusArr="this.orderStatusArr"
      :invoice="this.invoice"
    >
    </OrderReciept>
  </Layout>
</template>


<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>